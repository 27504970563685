<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin mb-16' : 'main px-5 space-y-2 mb-16']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home > Edit Liquidation Step 3</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">Edit Liquidation - Trip Report</p>
            </div>
            <!-- The activity creation steps -->
            <div class="flex items-center col-span-full mx-4 mt-5 pr-2">
              <span class="ml-4 mr-0.5  cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
              <div class="flex-auto cursor-pointer text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="ml-0.5 mr-0.5  cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">2</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="ml-0.5 mr-0.5 animate-pulse cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">3</span>
              <div class="flex-auto cursor-pointer animate-pulse border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">4</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm ring-emerald-600 font-medium ring-2 text-gray-500 py-0 px-1">5</span>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8 mt-4">
              <div
                class="ring-1 py-2 text-left cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
              >
                <div v-if="showTripReport" class="px-4 py-6 space-y-2.5">
                  <div class="mx-4 text-right">
                    <button
                        @click="reset()"
                        class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Reset Trip Report
                      </button>
                  </div>
                  <div class="mx-4 mb-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 gap-6">
                    <!-- the distribution list -->
                    <!-- <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Distribution List <span class="font-bold text-red-500">*</span></label>
                      <ckeditor v-model="distributionList" :config="backgroundConfig"></ckeditor>
                    </div> -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Distribution List <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'distribution-list'"
                            :url="'/employee/search'"
                            :persistedSelection="selectedDistributionList"
                            :multipleSelection="true"
                            @selectedItems="(items) => {
                              selectedDistributionList = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['firstname', 'surname', 'position']
                            }"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- the travellers -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Travellers Names <span class="font-bold text-red-500">*</span></label>
                      <ckeditor v-model="travellers" :config="editorConfig"></ckeditor>
                    </div>
                    <!-- team leader-->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Team Leader <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="teamLeader"
                          required
                          placeholder="Team Leader"
                          type="text"
                          disables
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- dates -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Dates <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="dates"
                          required
                          placeholder="Dates"
                          type="text"
                          disables
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- destiny -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Destiny <span class="font-bold text-red-500">*</span></label>
                      <ckeditor v-model="destiny" :config="editorConfig"></ckeditor>
                    </div>
                    <!-- trip purpose -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Trip Purpose <span class="font-bold text-red-500">*</span></label>
                      <ckeditor v-model="tripPurpose" :config="editorConfig"></ckeditor>
                    </div>
                    <!-- product of Visit -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Product of Visit (Outputs) <span class="font-bold text-red-500">*</span></label>
                      <ckeditor v-model="output" :config="editorConfig"></ckeditor>
                    </div>
                    <!-- key people contacted -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Key People Contacted <span class="font-bold text-red-500">*</span></label>
                      <ckeditor v-model="keyPeople" :config="editorConfig"></ckeditor>
                    </div>
                    <!-- general observation -->
                     <!-- Date	Local	Activity	Observations	Recommendations -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">General Observations <span class="font-bold text-red-500">*</span></label>
                      <ckeditor v-model="observation" :config="editorConfig"></ckeditor>
                    </div>
                  </div>
                <div class="w-full flex text-center mx-auto pt-1">
                    <div class="flex-1">
                      <button
                        @click="back"
                        class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Back
                      </button>
                      <button
                        @click="next"
                        class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { interval } from 'rxjs'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';
import { component as ckeditor } from '@mayasabha/ckeditor4-vue3'
import CustomDropDown from './CustomDropDown.vue'

export default {
  computed: {
    ...mapState([
      'employee',
      'liquidationRequest',
      'tripReport'
    ])
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message,
    ckeditor,
    CustomDropDown
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      showTripReport: false,
      title: 'Error Message',
      processMessage: '',
      distributionList: '',
      selectedDistributionList: {},
      travellers: '',
      teamLeader: '',
      dates: '',
      destiny: '',
      tripPurpose: '',
      output: '',
      keyPeople: '',
      observation: '',
      tripReportData: null,
      backgroundConfig: {
        // The configuration of the editor.
        toolbar: [['Cut', 'Copy', '-', 'PasteText', 'Paste', 'PasteFromWord', 'Strike', 'Outdent', 'HorizontalRule', 'Indent', 'Blockquote', '-', 'Undo', 'Redo', 'Scayt', '-', 'Bold', 'Italic', 'SpecialChar', 'RemoveFormat', '-', 'NumberedList', 'BulletedList', 'justify']]
      },
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
        toolbar: [['Cut', 'Copy', 'Link', 'Table', 'Fill', '-', 'PasteText', 'Paste', 'PasteFromWord', 'Strike', 'Outdent', 'HorizontalRule', 'Indent', 'Blockquote', '-', 'Undo', 'Redo', 'Scayt', '-', 'Bold', 'Italic', 'SpecialChar', 'RemoveFormat', '-', 'NumberedList', 'BulletedList', 'justify']]
      },
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 200)
      this.$store.commit('mutateSelectedSubMenuItem', 200)
      // load the vuex data
      this.loadTripReport()
      // start automatically saving data
      this.autosave()
    } 
  },
  methods: {
    // reset the trip report
    reset () {
      if (this.liquidationRequest !== null) {
        const id = this.liquidationRequest.id
        // get the request details
        this.getRequest(id)
      } 
    },
    // format the table data
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
        // check if the data is a date
        // formating the dates supplied
        var textDate = new Date(data)
        var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
        formattedData = formattedTextDate
      } else {
        formattedData = data
      }
      return formattedData
    },
    // load the vuex data
    loadTripReport () {
      // load the selected budget source
      if ( this.tripReport === null && this.liquidationRequest !== null) {
        const id = this.liquidationRequest.id
        // get the request details
        this.getRequest(id)
      } else {
        // load the vuex details
        this.selectedDistributionList = this.tripReport.distributionList
        this.travellers = this.tripReport.travellers
        this.teamLeader = this.tripReport.teamLeader
        this.dates = this.tripReport.dates
        this.destiny = this.tripReport.destiny
        this.tripPurpose = this.tripReport.tripPurpose
        this.output = this.tripReport.output
        this.keyPeople = this.tripReport.keyPeople
        this.observation = this.tripReport.observation
      }
      this.showTripReport = true
    },
    autosave () {
      // save details every 8 milliseconds
      const observable = interval(800 * 1 * 2)
      this.subscription = observable.subscribe({
        next: () => {
          const data = {
            distributionList: this.selectedDistributionList,
            travellers: this.travellers,
            teamLeader: this.teamLeader,
            dates: this.dates,
            destiny: this.destiny,
            tripPurpose: this.tripPurpose,
            output: this.output,
            keyPeople: this.keyPeople,
            observation: this.observation
          }
          this.$store.commit('mutateTripReport', data)
        },
        error: () => {}
      })
    },
    // getting the pending activity approvals
    getRequest (id) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/request/findById?id=${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })
      const observer = {
        next: (response) => {
          const data = response.response
          const size = data.length
          this.showTripReport = false
          // check the size
          if (size > 0) {
            // set the item details
            const advance = data[0].advance.advance
            const tor = data[0].advance.tor
            this.showTripReport = true
            
            const teamLeaderName = tor.teamLeader.name
            const startDate = advance.departureDate
            const endDate = advance.returnDate
            const destinations = advance.destinations
            const outputs = tor.output
            const objectives = tor.specificObjectives
            const objectiveSize = objectives.length
            const destinationSize = destinations.length
            const travellersData = advance.companions
            // const size = travellersData.length

            // set the travellers details
            let travellersList = '<ul>'
            // for (let i = 0; i < size; i++) {
            //   const data = travellersData[i].name
            //   travellersList += `<li>${data}</li>`
            // }
            travellersList += `<li>${travellersData}</li>`
            travellersList += '</ul>'
            this.travellers = travellersList

            // set the team leader
            this.teamLeader = `${teamLeaderName}`
            // set the dates
            this.dates = `${this.formatData(startDate)} to ${this.formatData(endDate)}`

            // set the destiny details
            let destinyList = '<ul>'
            for (let i = 0; i < destinationSize; i++) {
              const data = destinations[i].name
              destinyList += `<li>${data}</li>`
            }
            destinyList += '</ul>'
            this.destiny = destinyList

            // set the objective details
            let objectiveList = '<ul>'
            for (let i = 0; i < objectiveSize; i++) {
              const data = objectives[i].name
              objectiveList += `<li>${data}</li>`
            }
            objectiveList += '</ul>'
            this.tripPurpose = objectiveList

            // set the output details
            this.output = outputs

            // set the key observations
            const mie = data[0].advance.mie

            if (mie !== null && mie !== undefined) {
              const size = mie.length

              // the last row should not contain any data 
              let observationdata = `<table border='1' style='border: 1px; width: 100%; border-collapse: collapse;'>
                                      <tr>
                                          <th>Date </th>
                                          <th>Location </th>
                                          <th>Activity </th>
                                          <th>Observations </th>
                                          <th>Recommendations </th>
                                      </tr> `
              for (let i = 0; i < (size -1); i++) {
                const item = mie[i]
                const date = item.startDate
                const location = item.location.name
                const activity = item.description
                const row = `<tr>
                              <td>${this.formatData(date)} </td>
                              <td>${location}</td>
                              <td>${activity}</td> 
                              <td> </td>
                              <td> </td> 
                            </tr>`
                observationdata += row
              }

              const row = `<tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>`

              observationdata += row
              console.log(observationdata)

              // console.log(observationdata)
              this.observation = observationdata
              // this.observation = destinyList
            }
          }
          this.showTripReport= true

          const reportData = {
            distributionList: this.selectedDistributionList,
            travellers: this.travellers,
            teamLeader: this.teamLeader,
            dates: this.dates,
            destiny: this.destiny,
            tripPurpose: this.tripPurpose,
            output: this.output,
            keyPeople: this.keyPeople,
            observation: this.observation
          }
          this.$store.commit('mutateTripReport', reportData)
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    },
    // calculates the date difference in days for the schedule days
    dateDiffInDays () {
        // the date which the activity starts
        const days = []
        const scheduleSize = this.scheduleData.length

        for (let i = 0; i < scheduleSize; i++) {
          const startDate = this.scheduleData[i].startDate
          days.push(startDate)
        }

        // sort the days and get the minimum day
        days.sort()
        const miniDate = days[0]
        const dateFrom = new Date(miniDate)


        // today's date
        const dateTo = new Date()

        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // remove the time and time-zone details.
        const utc1 = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
        const utc2 = Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      },
    // move back
    back () {
      if (this.subscription !== null) {
        this.subscription.unsubscribe()
      }
      const data = {
        distributionList: this.selectedDistributionList,
        travellers: this.travellers,
        teamLeader: this.teamLeader,
        dates: this.dates,
        destiny: this.destiny,
        tripPurpose: this.tripPurpose,
        output: this.output,
        keyPeople: this.keyPeople,
        observation: this.observation
      }
      this.$store.commit('mutateTripReport', data)
      this.$router.push('/edit-liquidation-2')
    },
    // move to the next page
    next () {
      // save the data
      // this.autosave()
      // validate the data
      if (Array.isArray(this.selectedDistributionList) && this.selectedDistributionList.length > 0 && this.travellers.trim() !== '' && this.teamLeader.trim() !== '' && this.dates.trim() !== '' && this.destiny.trim() !== '' && this.tripPurpose.trim() !== '' && this.output.trim() !== '' && this.keyPeople.trim() !== '' && this.observation.trim() !== '') {
        if (this.subscription !== null) {
          this.subscription.unsubscribe()
        }
        const data = {
          distributionList: this.selectedDistributionList,
          travellers: this.travellers,
          teamLeader: this.teamLeader,
          dates: this.dates,
          destiny: this.destiny,
          tripPurpose: this.tripPurpose,
          output: this.output,
          keyPeople: this.keyPeople,
          observation: this.observation
        }
        this.$store.commit('mutateTripReport', data)

        this.$router.push('/edit-liquidation-4')
      } else {
        // fill all the fields
        // close the loader modal
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.processMessage = 'Please fill all the fields to continue.'
        // open the error modal
        this.$refs.messageRef.openModal()
      }
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // submits the signature
    submit (filename) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/signature/insertFile',
        method: 'POST',
        body: {
          employee_id: this.employee.id,
          filename: filename
        },
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const observer = {
        next: () => {
          // move to the profile page
          this.$router.push('/employee-profile')
        },
        error: () => {
          // close the loader modal
          this.$refs.loaderRef.closeModal()
          // showing the dialog for the error
          this.processMessage = 'Failed to submit the signature, please try again.'
          // open the error modal
          this.$refs.messageRef.openModal()
        },
        complete: () => {
          this.$refs.loaderRef.closeModal()
        }
      }

      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
