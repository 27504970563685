<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
          <span><span class=" text-emerald-500">>Home</span> > Processed 25% Liquidations</span>
          <span v-if="employee!==null">{{ employee.firstname }} {{ employee.surname }}</span>
        </div>
          <div>
          <!-- Timeline -->
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m1,13c-.553,0-1-.448-1-1C0,5.383,5.383,0,12,0c3.449,0,6.733,1.518,9,4.087V1c0-.552.447-1,1-1s1,.448,1,1v4c0,1.103-.897,2-2,2h-4c-.553,0-1-.448-1-1s.447-1,1-1h2.122c-1.867-1.893-4.434-3-7.122-3C6.486,2,2,6.486,2,12c0,.552-.447,1-1,1Zm22-2c-.553,0-1,.448-1,1,0,5.514-4.486,10-10,10-2.688,0-5.255-1.107-7.123-3h2.123c.553,0,1-.448,1-1s-.447-1-1-1H3c-1.103,0-2,.897-2,2v4c0,.552.447,1,1,1s1-.448,1-1v-3.087c2.267,2.569,5.551,4.087,9,4.087,6.617,0,12-5.383,12-12,0-.552-.447-1-1-1Zm-12.142,8.919c.374.054.759.081,1.142.081,4.411,0,8-3.589,8-8,0-.78-.111-1.55-.332-2.287-.159-.529-.715-.832-1.244-.671-.529.158-.83.715-.672,1.245.165.551.248,1.127.248,1.713,0,3.309-2.691,6-6,6-.288,0-.577-.021-.858-.061-.542-.078-1.052.301-1.132.848-.078.546.302,1.053.849,1.131Zm1.142-13.919c.286,0,.575.021.859.061.55.079,1.053-.303,1.131-.85.077-.546-.303-1.053-.85-1.131-.377-.053-.761-.081-1.141-.081-4.411,0-8,3.589-8,8,0,.777.111,1.546.331,2.286.13.434.527.715.959.715.094,0,.19-.014.285-.042.529-.157.831-.714.674-1.244-.165-.555-.249-1.132-.249-1.715,0-3.309,2.691-6,6-6Zm1.126,8.2h-2.204c-.223,0-.434-.107-.564-.287-.142-.193-.433-.213-.552-.213-.381,0-.628.246-.732.477-.131.291-.086.604.122.84.438.498,1.066.783,1.727.783h.278v.7c0,.441.358.8.8.8s.8-.359.8-.8v-.7h.326c.647,0,1.257-.287,1.673-.787.414-.499.583-1.156.463-1.803-.16-.853-.889-1.551-1.812-1.735l-2.688-.539c-.267-.054-.461-.291-.461-.562,0-.316.257-.574.573-.574h2.204c.223,0,.434.107.564.287.142.193.433.213.552.213.381,0,.628-.246.732-.476.132-.291.086-.605-.121-.841-.438-.497-1.067-.782-1.728-.782h-.277v-.7c0-.441-.358-.8-.8-.8s-.8.359-.8.8v.7h-.327c-.646,0-1.256.287-1.672.786-.414.499-.583,1.156-.463,1.804.159.853.887,1.55,1.812,1.735l2.688.538c.268.054.462.291.462.563,0,.316-.258.574-.574.574Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600"> 25% Liquidations</p>
            </div>
            <!-- {{ requestData }} -->
              <!-- The pending activity approvals -->
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-8 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-6 sm:gap-y-6">
                <div class=" flex">
                  <input
                    v-model="filter"
                    type="text"
                    placeholder="Search..."
                    required class="shadow-sm bg-gray-50 text-gray-600 w-full text-size border px-2 py-3 border-gray-300  focus:outline-none focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 "/>
                </div>

                <div class=" overflow-x-auto">
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs pointer-events-none" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">25% Liquidation Date</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Employee</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Request Reason</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Departure Date</td>
                        <!-- <td class="py-3 px-2 border text-left text-white font-bold">Return Date</td> -->
                        <td class="py-3 px-2 border text-left text-white font-bold">Funding</td>
                        <!-- <td class="py-3 px-2 border text-left text-white font-bold">MIE Advance (75%)</td> -->
                        <td class="py-3 px-2 border text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr  v-for="(data, i) in filteredRows" :key="i" class="text-gray-500 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ data.date }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.firstname }} {{ data.surname }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.advance.advance.reasonOfTravel.substring(0, 32) }}...</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.advance.advance.returnDate)}}</td>
                        <!-- <td class="py-3 px-2 border text-left">{{ formatData(data.advance.advance.returnDate)}}</td> -->
                        <td class="py-3 px-2 border text-left">{{ data.advance.advance.budgetSource.map((data) => data.name).join(', ')}}</td>
                        <!-- <td class="py-3 px-2 border text-left">{{ formatData(data.advance.mie[(data.advance.mie.length - 1)].advance)}}</td> -->
                        <td class="py-3 px-2 border text-center">
                          <div class="flex items-center justify-center">
                            <button v-if="data.approved_twenty_five_id === null || data.approved_twenty_five_id === undefined" @click="trackRequest(data.id)" class="bg-emerald-500 text-white px-4 py-1">Track</button>
                            <button v-else @click="trackApprovedRequest(data.id)" class="bg-emerald-500 text-white px-4 py-1">Track</button>
                            <button @click="viewLiquidation(data.id)" class="bg-emerald-500 text-white px-4 py-1">View</button>
                            <button v-if="data.approved_twenty_five_id !== null && data.approved_twenty_five_id !== undefined" @click="downloadLiquidation(data.id)" class="bg-emerald-500 text-white px-4 py-1">Download</button>
                            <button v-else class="bg-gray-400 text-white px-4 py-1">Download</button>
                          </div>
                        </td>
                        <!-- <td class="py-3 px-2 border text-left">{{ formatData(data.date) }}</td>
                        <td class="py-3 px-2 border text-left">{{data.memo.title}}</td>
                        <td class="py-3 px-2 border text-left">{{ data.memo.destination.name }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.memo.budgetSource.map((data) => data.name).join(',') }}</td>
                        <td class="py-3 px-2 border text-center">{{ data.memo.currency.allData.acronym }}</td>
                        <td class="py-3 px-2 border text-center">{{ formatData(data.budget[0].data[(data.budget[0].data.length -1)].total * 0.75) }}</td>
                        <td class="py-3 px-2 border text-center">{{ formatData(data.budget[0].data[(data.budget[0].data.length -1)].total) }}</td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>


              </div>
              <div class="flex mb-5">
                <div class=" flex mx-auto items-center">
                  <div v-if="startRow !== 0" @click="movePages(-1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer hover:bg-emerald-300 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div v-if="(startRow+rowsPerPage) < requestData.length" @click="movePages(1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer border-l hover:bg-emerald-300 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
   <!-- the loader modal -->
   <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import Loader from './modals/Loader.vue'
import Message from './modals/Message.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { retry, concat } from 'rxjs'

export default {
  computed: {
    ...mapState([
      'employee'
    ]),
    filteredRows () {
      return this.requestData.filter((row) => {
        const searchText = this.filter.toLowerCase()

        return JSON.stringify(row).toLowerCase().includes(searchText)
      }).slice(this.startRow, this.startRow + this.rowsPerPage)
    }
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message
  },
  setup () {
    return {
    }
  },
  name: 'approval-history',
  props: {
  },
  data: () => {
    return {
      filter: '',
      startRow: 0,
      rowsPerPage: 10,
      endPageItemIndex: 10,
      showRequests: false,
      expandBody: false,
      requestData: [],
      title: '',
      processMessage: '',
      mieAdvance: '0',
      lodgingAdvance: '0',
      otherAdvance: '0',
      BASE_URL: process.env.VUE_APP_BASE_URL,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    this.$store.commit('mutateActiveListItemIndex', 500)
    this.$store.commit('mutateSelectedSubMenuItem', 511)
    // getting the pending activity approvals
    this.getLiquidations()
    this.$store.commit('mutateLiquidation25Request', null)
    this.$store.commit('mutateLiquidation25VoucherDetails', null)
    this.$store.commit('mutateLiquidation25VoucherSheet', null)
    this.$store.commit('mutateLiquidation25RequestData', null)
  },
  methods: {
    // getting the pending activity approvals
    getRequest (id) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/request/findById?id=${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })
      const observer = {
        next: (response) => {
          const data = response.response
          const size = data.length
          // check the size
          if (size > 0) {
            const advance = data[0].advance
            let mie = null
            let lodging = null
            let other = null
            const budgetSources = advance.advance.budgetSource
            const currency = advance.advance.currency

            if (advance.mie !== undefined && advance.mie !== null && Array.isArray(advance.mie) && advance.mie.length > 0) {
              mie = advance.mie
              const mieSize = mie.length
              if (mieSize > 0) {
                this.mieAdvance = mie[(mieSize - 1)].advance
              }
            }

            if (advance.lodging !== undefined && advance.lodging !== null && Array.isArray(advance.lodging) && advance.lodging.length > 0) {
              lodging = advance.lodging 
              const lodgingSize = lodging.length
              this.lodgingAdvance = lodging[(lodgingSize - 1)].total
            }

            if (advance.other !== undefined && advance.other !== null && Array.isArray(advance.other) && advance.other.length > 0) {
              other = advance.other 
              const otherSize = other.length
              this.otherAdvance = other[(otherSize - 1)].total
            }
            
            // save the liquidation advances
            this.$store.commit('mutateLiquidationAdvances', { mie: this.mieAdvance, lodging: this.lodgingAdvance, other: this.otherAdvance })
            this.$store.commit('mutateLiquidationCurrencyAndFunding', { budgetSources: budgetSources, currency: currency })
          }
          // get the mie, other & lodging advances
          this.$router.push('/edit-liquidation-1')
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    },
    resubmit (data) {
      localStorage.setItem('edit25LiquidationId', data.id)

      const requestId = data.request_id 
      const firstname = data.firstname 
      const surname = data.surname
      const voucher = data.voucher
      const advance = data.advance.advance 
      const reasonOfTravel = advance.reasonOfTravel
      const requestDate = data.request_date
      const voucherDetails = voucher.details
      const voucherSheet = voucher.sheet

      // setup the first step
      const selectedRequest = { id: requestId, name: `${firstname} ${surname}, ${requestDate}, ${reasonOfTravel}` }
      this.$store.commit('mutateLiquidation25Request', selectedRequest)

      // setup the step 2
      this.$store.commit('mutateLiquidation25VoucherDetails', voucherDetails)

      // setup the step 3
      this.$store.commit('mutateLiquidation25VoucherSheet', voucherSheet)
      // console.log(data)
      this.getEditRequest(requestId)
    },
     // getting the pending activity approvals
     getEditRequest (id) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/request/findById?id=${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })
      const observer = {
        next: (response) => {
          const data = response.response
          const size = data.length
          // check the size
          if (size > 0) {
            this.$store.commit('mutateLiquidation25RequestData', data[0])
            this.$router.push('/edit-25liquidation-1')
          }
          // get the mie, other & lodging advances
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    },
    downloadLiquidation (id) {
        this.$refs.loaderRef.openModal()
        const observables = []
        const urls = [`/twenty-five-liquidation/downloadVoucher?id=${id}`]
        const filename = [`25-percent-liquidation-voucher-${id}.pdf`]
        const size = urls.length

        for (let i = 0; i < size; i++) {
          const realFilename = filename[i]
          const url = urls[i]
          const fileObservable = ajax({
            method: 'GET',
            url: process.env.VUE_APP_BASE_URL + url,
            headers: {
              Authorization: `Bearer ${this.employee.token}`,
              filename: realFilename
            },
            responseType: 'blob'
          })

          observables.push(fileObservable)
        }

        const observable = concat(...observables)

        
        const observer = {
          next: (response) => {
            const blob = response.response
            // const headers = response.responseHeaders
            const filename = response.request.headers.filename
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // showing the dialog for the error
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.processMessage = 'Failed to download the file.'
            // open the error modal
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
          }
        }

        observable.subscribe(observer)
    },
    // moves the pages of the pagenation
    movePages (amount) {
      var newStartRow = this.startRow + (amount * this.rowsPerPage)
      // only move if its greater than 0
      if (newStartRow >= 0 && newStartRow < this.requestData.length) {
        this.startRow = newStartRow
      }
    },
    download (requestId, title) {
      // download the request in a pdf form
      this.$refs.loadingModal.openLoaderModal()
        const observable = ajax({
          method: 'GET',
          url: this.BASE_URL + '/request/downloadTravelRequest?id=' + requestId,
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          },
          responseType: 'blob'
        })

        const observer = {
          next: (response) => {
            const filename = title.trim().replace(/ /g,'-').toLowerCase() + '-request'
            const blob = response.response
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to download the travel request.'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
          }
        }

        observable.subscribe(observer)
      
    },
    viewLiquidation (id) {
      localStorage.setItem('liquidation25Id', id)
      this.$router.push('/employee-view-25liquidation')
    },
    trackRequest (id) {
      localStorage.setItem('liquidation25Id', id)
      this.$router.push('/employee-track-25liquidation')
    },
    trackApprovedRequest (id) {
      localStorage.setItem('liquidation25Id', id)
      this.$router.push('/employee-track-approved-25liquidation')
    },
    // format the table data
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
        // check if the data is a date
        // formating the dates supplied
        var textDate = new Date(data)
        var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
        formattedData = formattedTextDate
      } else {
        formattedData = data
      }
      return formattedData
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // getting the pending activity approvals
    getLiquidations () {
      this.showRequests = false
      const observable = ajax({
        url: this.BASE_URL + `/twenty-five-liquidation/findProcessed`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        retry(1)
      )
      const observer = {
        next: (response) => {
          // console.log(response)
          const data = response.response
          this.requestData = data
          this.showRequests = true
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
